<template>
  <div id="manufacturer">
    <v-container class="secondary mt-10">
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="manufacturers"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="primary--text font-weight-bold"
                  >Блюда</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-text-field
                  class="mr-10"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Поиск"
                  single-line
                  hide-details
                ></v-text-field>
                
                <v-btn outlined icon @click="createItem">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-dialog v-model="dialogCreate" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">
                      Добавить производителя
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="newItem.name"
                        label="Наименование"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="createItemConfirm"
                        >Добавить</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="closeCreate"
                        >Отменить</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Вы точно хотите удалить этого
                      производителя?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Отменить</v-btn
                      >
                      <v-btn
                        color="red darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Удалить</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="editItem(item)">{{ item.name }}</td>
                <td @click="editItem(item)">{{ item.description }}</td>
                <td style="text-align: center">
                  <v-icon small @click="deleteItem(item)">mdi-dots-vertical</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Manufacturer",

  data: () => ({
    dialogDelete: false,
    dialogCreate: false,
    search: "",
    newItem: {
      name: "",
    },
    headers: [
      {
        text: "Наименование",
        align: "start",
        value: "name",
      },
      { text: "Описание", value: "description" },
      { text: "Действия", value: "actions", sortable: false },
    ],
    manufacturers: [],
    profiles: [],
    error: null,
  }),

  created() {
    this.initialize();
    // this.profileInfo();
  },

  methods: {
    initialize() {
      this.$http
        .get("/api/product/GetRestaurantProducts?restaurantId=55DE9A40-561E-4F44-9AFF-9A8D048165FA")
        .then((response) => {
          this.manufacturers = response.data;
        })
        .catch((error) => (this.error = error));
    },
    profileInfo() {
      this.$http
        .get("/api/Contact/GetMyProfile")
        .then((response) => {
          this.profiles = response.data;
        })
        .catch((error) => (this.error = error));
    },


    deleteItem(item) {
      this.editedIndex = this.manufacturers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`api/v1/account/delete?accountId=${this.editedItem.accountId}`)
        .then(() => {
          this.manufacturers.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((error) => {
          alert(error);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
    },

    closeCreate() {
      this.dialogCreate = false;
    },

    createItemConfirm() {
      this.$http
        .post("api/v1/moderator/quickcreatemanufacturer", this.newItem)
        .then(() => {
          this.initialize();
          this.dialogCreate = false;
          this.newItem = {
            name: "",
          };
        })
        .catch((error) => {
          this.error = error;
        });
    },

    editItem(item) {
      this.$router.push({ path: `/admin/manufacturer/${item.id}` });
    },
  },
};
</script>
